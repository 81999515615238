@import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
@import './storyblokComponents/SbHorizontalSlider/slider.css';
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';


:root {
  --primaryGradient: linear-gradient(90deg, #5aaeff 0%, #e757ff 100%);
  --secondaryGradient: radial-gradient(100.06% 737.56% at 0% 26.79%, #920000 0%, #f86c6c 100%);
  --blueColor: #5aaeff;
  --magentaColor: #e757ff;
  --darkRedColor: #920000;
  --salmonColor: #f86c6c;
  --blackColor: #0e0726;
  --gray1Color: #3a3858;
  --gray2Color: #66698a;
  --gray3Color: #919abb;
  --gray4Color: #bdcbed;
  --whiteColor: #fff;
  --primaryColor: #bdcbed;
  --secondaryColor: #100146;
  --blueBackground: #5aaeff;
  --lightRedColor: #edbdbd;
  --bs-body-font-family: montserrat;
  --bs-body-font-size: 20px;
  --bs-body-line-height: 30px;
  --bs-body-font-weight: 500;
  --bs-body-color: var(--primaryColor);
  --bs-body-bg: #0e0726;
  --bs-light-rgb: 244, 242, 253;
  --darkRedColorBackground: #18070c;
  --babyBlue: #7cb1ff;
  --fucsia: #e757ff;
  --black: #0e0726;
  --white: #fff;
  --darkBlue: #2e2a8a;
  --darkBlueGray: #66698a;
  --blueGray: #bdcbed;
  --blueGrayDark: #281d47;
  --meta: #a97eff;
  --gray1: #3a3858;
  --gray2: #66698a;
  --gray3: #919abb;
  --grayBlue: #201b3a;
  --grayLightBlue: #bdcbed;
  --grayBlueDoubleLight: #d7ddf5;
  --lightBlue: #a9d5ff;
  --lightFucsia: #ef8fff;
  --lightMeta: #cab0ff;
  --darkMeta: #45378b;
  --blueGrayLight: #4b476d;
  --gunPowder: #3A3858;
  --gradient: linear-gradient(90deg, #5aaeff 0%, #e757ff 100%);
  --linearGradient: linear-gradient(116.04deg, #5aaeff 0%, #e757ff 176.27%);
}

@keyframes load {
  from {
    width: 0;
    max-width: 0;
  }

  to {
    width: 100%;
  }
}

body {
  min-height: fill-available;
  min-height: -webkit-fill-available;
}

html {
  height: fill-available;
  height: -webkit-fill-available;
}

#backgroundChanger {
  content: '';
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  top: 0;
  opacity: 0;
  background: var(--darkRedColorBackground);
}

.pointer {
  cursor: pointer;
}

.richText {
  margin-top: 30px;
  margin-bottom: 50px;
}

.richText ul {
  margin-top: 20px;
}

.richText h1 {
  font-size: 25px !important;
  line-height: 36px;
  text-align: left;
}

.richText h2,
.richText h3,
.richText h4,
.richText h5,
.richText h6,
.richText p {
  color: var(--gray2) !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.richText * {
  color: var(--gray1);
}

b {
  font-weight: 700;
}

img {
  max-width: 100%;
}

h1 {
  font-size: 80px;
  line-height: 88px;
  display: flex;
  font-weight: 500;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--whiteColor);
  margin-bottom: 30px;
}

h1.big {
  font-size: 120px;
  line-height: 146px;
}

h1.small {
  font-size: 60px;
  line-height: 66px;
}

a,
a:hover {
  text-decoration: none;
  font-weight: 700;
  color: var(--whiteColor);
}

h2 {
  font-size: 54px;
  line-height: 54px;
  letter-spacing: -0.03em;
  color: var(--whiteColor);
  margin-bottom: 0;
}

h3 {
  font-size: 35px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--whiteColor);
  margin-bottom: 15px;
}

h4 {
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  text-align: right;
  letter-spacing: -0.03em;
}

h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  background: var(--primaryGradient);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  display: inline-block;
}

h6 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: var(--whiteColor);
  margin-bottom: 0;
}

.dark {
  color: var(--blackColor);
}

.gradientText {
  display: inline-block;
  background: linear-gradient(90deg, #5aaeff 0%, #e757ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* ------- START BUTTONS STYLING ------- */

.btn {
  margin: 0;
  min-width: 275px;
  height: 60px;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.03em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 35px;
  border: none;
  border-radius: 0;
  white-space: nowrap;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: transparent !important;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: var(--primaryGradient);
  color: var(--white) !important;
  box-shadow: 0 4px 68px rgba(90, 174, 255, 0.42);
}

.btn-primary:hover {
  background: var(--primaryGradient) !important;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
  background: transparent !important;
  color: var(--white) !important;
  border: 2px solid var(--white) !important;
  box-shadow: none !important;
}

.btn-primary::after,
.btn-secondary::after {
  content: url('./assets/icons/arrowIconWhite.svg');
  opacity: 0;
  width: 0;
  transition: all ease-in 0.5s;
  filter: brightness(3);
}

.btn-secondary::after {
  content: url('./assets/icons/arrowIconWhite.svg');
  opacity: 0;
  width: 0;
  transition: all ease-in 0.5s;
}

.btn-primary:hover::after,
.btn-secondary:hover::after {
  width: 30px;
  opacity: 1;
  margin-left: 20px;
}

.btn-primary:disabled {
  color: var(--blackColor);
}

.btn-secondary:disabled {
  color: var(--gray2Color) !important;
  border-color: var(--gray2Color) !important;
}

/* ------- END BUTTONS STYLING ------- */

input.form-control,
input.form-control:focus {
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid var(--whiteColor);
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.03em;
  color: var(--whiteColor);
  padding: 10px 0;
}

.accordion-item {
  background-color: transparent;
  border: none;
  border-radius: 0 !important;
  border-top: 2px solid var(--gray2);
  border-bottom: 2px solid var(--gray2);
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.03em;
}

.accordion-body {
  padding: 0 0 40px;
}

.accordion-button {
  background-color: transparent;
  color: var(--whiteColor);
  font-size: 35px;
  line-height: 110%;
  padding: 40px 40px 40px 0;
  letter-spacing: -0.03em;
}

.accordion-button::after,
.accordion-button:not(.collapsed)::after {
  display: none;
}

.accordion-button:not(.collapsed) {
  background: linear-gradient(90deg, #5aaeff 0%, #e757ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.accordion-button:focus {
  box-shadow: none;
  border: none;
}

@media (min-width: 768px) {
  .owl-carousel#secondCarousel .owl-stage {
    padding-left: 0 !important;
    padding-right: 200px !important;
    display: flex;
  }

  .owl-carousel#firstCarousel .owl-stage {
    padding-left: 200px !important;
    padding-right: 0 !important;
    display: flex;
  }
}

.owl-carousel#firstCarousel {
  margin-bottom: 20px;
}

@keyframes beatingHeartAnimation {
  0% {
    transform: scale(1);
  }

  5% {
    transform: scale(0.9);
  }

  10% {
    transform: scale(0.8);
  }

  15% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(0.8);
  }
}

@keyframes bounce {
  50% {
    transform: translateY(-10px);
  }
}

.bounce {
  animation: bounce 2s infinite;
}

.beatingAnimation:hover {
  animation: beatingHeartAnimation 1.2s infinite;
}

.hoverDetails {
  display: none;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(8, 0, 34, 0.7);
  position: absolute;
  top: 0;
  font-family: Montserrat, serif;
}

.hoverDetails .hoverCountry {
  font-family: Montserrat, serif;
  font-size: 13px;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  background: linear-gradient(90deg, #5aaeff 0%, #e757ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hoverDetails .hoverName {
  font-size: 29.5px;
  line-height: 30px;
  letter-spacing: -0.03em;
  color: #fff;
}

.swiper-wrapper {
  transition-timing-function: linear !important;
}

.fullWidthContainer {
  padding-left: 0;
  padding-right: 0;
}

div[class^='assets_mw-active-widget'] {
  display: none !important;
}

@media (max-width: 768px) {
  h1 {
    font-size: 35px;
    line-height: 39px;
  }

  h1.small {
    font-size: 35px;
    line-height: 39px;
  }

  h1.big {
    font-size: 60px;
    line-height: 66px;
  }

  h2 {
    font-size: 49px;
    line-height: 49px;
  }

  h3.small {
    font-size: 19.6809px;
    line-height: 22px;
    letter-spacing: -0.03em;
  }

  h5 {
    font-size: 16px;
    line-height: 19px;
  }

  h5.small {
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.2em;
  }

  h6 {
    font-size: 18px;
    line-height: 22px;
  }

  p.small {
    font-size: 11px;
    line-height: 17px;
    letter-spacing: -0.03em;
  }

  .accordion-button {
    font-size: 35px;
    line-height: 39px;
    padding: 20px 20px 20px 0;
  }
}