.rewardsContainer {
  margin-bottom: 50px !important;
}

.firstBackgroundGradient {
  position: absolute;
  height : 50%;
  width: 50%!important;
  top:25%;
  background: linear-gradient(180deg, rgba(0, 194, 255, 0) 0%, #FF29C3 100%);
  filter: blur(350px);
  border-radius: 50%;
  z-index: -1;
  transform: rotate(170deg);
  opacity: 0.5;
}

.secondBackgroundGradient{
  position: absolute;
  width: 50%!important;
  height : 50%;
  left:25%;
  top:25%;
  background: linear-gradient(280deg, rgba(42, 24, 255, 0) 0%, #174AFF 100%);
  filter: blur(200px);
  border-radius: 50%;
  z-index: -1;
  transform: rotate(170deg);
}

.legalTitle {
  background: linear-gradient(90deg, #5aaeff 0%, #e757ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.title {
  color: white;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
}

.description {
  margin-top: 20px;
  color: var(--primaryColor);
  text-align: center;
  font-size: 20px;
  line-height: 30px;
}

@media (min-width: 768px) {
  .title {
    font-size: 80px;
    line-height: 88px;
  }

  .rewardsContainer {
    margin-bottom: 100px !important;
  }
}
