.cardsContainer {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
}

.card {
  position: relative;
  border: 2px solid rgba(189, 203, 237, 0.3);
  padding: 33px 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 1 100%;
}

.recommendedBox {
  position: absolute;
  left: -2px;
  top: 100%;
  width: calc(100% + 4px);
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  letter-spacing: 0.2em;
  line-height: 15px;
  color: var(--black);
  z-index: -1;
  background: var(--gradient);
}

.active {
  margin-bottom: 30px;
  background: radial-gradient(125.47% 218.21% at -20.47% 127.99%,
      rgba(90, 174, 255, 0.2) 0%,
      rgba(90, 174, 255, 0) 100%),
    (var(--black));
  box-shadow: 0px 0px 33.4907px rgba(90, 174, 255, 0.3);
  border-color: var(--fucsia);
  border-left-color: var(--blueColor);
  border-bottom: none;
}

@media (min-width: 768px) {
  .title {
    font-size: 80px;
    line-height: 88px;
  }

  .card {
    flex: 0 1 calc(33% - 30px);
  }

  .active {
    margin: 0;
  }

  .cardsContainer {
    gap: 50px;
  }
}