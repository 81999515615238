.playBtn {
  font-size: 100px;
  color: var(--white);
  border-radius: 50%;
  border: 10px solid var(--white);
}

.reactPlayerStyle {
  position: relative;
  padding-top: 56.25%;
  border-radius: 30px;
  overflow: hidden;
}

.reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
}

.muteWrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 0;
  height: 100%;
  opacity: 0;
  transition: opacity 1s;
}

.reactPlayerStyle:hover .muteWrapper {
  opacity: 1;
  width: 100%;
}

.volumeIcon {
  color: var(--blueGray);
  padding: 10px;
  font-size: 50px;
  background-color: rgba(32, 27, 58, 0.7);
  border-radius: 10px;
  margin: 30px;
}
