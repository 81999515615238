.imageContainer {
  width: 100%;
  min-height: 115px;
  max-height: 115px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(40, 29, 71, 0.7);
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 200ms ease;
}

.mainImageContainer {
  padding: 30px 20px;
  min-height: 125px;
}

.imageContainer:hover {
  background: rgba(40, 29, 71, 1);
}
