.wrapper {
  background: url("../../../../assets/images/rarissimeBannerWeb2.jpeg") no-repeat center center ;
  background-size: cover;
  width: 100%;
  height: auto;
}

.goToPresaleBtn {
  font-size: 34px !important;
  border: 5px solid !important;
  margin: 600px auto auto !important;
}

@media (max-width: 768px) {
  .wrapper {
    background: url("../../../../assets/images/rarissimeBannerMobile2.jpeg") no-repeat center center ;
    background-size: cover;
    height: 100%;
  }

  .goToPresaleBtn {
    /*margin-top: 96vw !important;*/
    margin-top: 50px;
  }
}
