.primaryHeadline {
  font-weight: 600;
  font-size: 68px;
  line-height: 71px;
  letter-spacing: -0.03em;
  font-feature-settings: "liga off";
  white-space: pre-line;
}

.secondaryHeadline {
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -0.03em;
  font-feature-settings: "case" on;
}

.tertiaryHeadline {
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: -0.03em;
}

.quaternaryHeadline {
  font-size: 28px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: -0.03em;
}

.pentaHeadline {
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.04em;
}

.caption {
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.body1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
}

.body2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.03em;
}

.body3 {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.03em;
}

/* to remove after restyle landing and presale */
.body4 {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: left;
}

.body5 {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.04em;
  font-feature-settings: "case" on;
}

.body6 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.03em;
}


.button {
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: -0.04em;
}

.label {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.detail {
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.underline {
  text-decoration: underline;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.gradientColor {
  background: var(--gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 768px) {
  .primaryHeadline {
    font-size: 40px;
    line-height: 44px;
  }

  .secondaryHeadline {
    font-size: 32px;
    line-height: 38px;
  }

  .tertiaryHeadline {
    font-size: 28px;
    line-height: 28px;
  }

  .quaternaryHeadline {
    font-size: 22px;
    line-height: 24px;
  }

  .pentaHeadline {
    font-size: 20px;
    line-height: 24px;
  }

  .caption {
    font-size: 13px;
    line-height: 13px;
  }

  .body1 {
    font-size: 18px;
    line-height: 27px;
  }

  .body2 {
    font-size: 16px;
    line-height: 21px;
  }

  .body3 {
    font-size: 13px;
    line-height: 20px;
  }

  .button {
    font-size: 22px;
    line-height: 22px;
  }

  .detail {
    line-height: 15px;
  }

  .label {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.15em;
  }
}
