.headerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wrapper {
  position: fixed;
  padding: 25px;
  z-index: 9999;
  width: 100%;
  top: 0;
  border: none;
  transition: all 0.3s;
}

.wrapper.sticky {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(100px);
}

.wrapper.sticky.darkWrapper {
  background: rgba(0, 0, 0, 0.15);
}

.menu {
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--whiteColor);
  cursor: pointer;
}

.menu > p {
  font-size: 14px;
  line-height: 17px;
  margin: 0;
}

.menuIcon {
  transition: all 0.5s;
}

.menu:hover .menuIcon {
  transform: rotate3d(30, 30, 30, 360deg);
}

.optionsContainer {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 10px;
}

@media (min-width: 768px) {
  .optionsContainer {
    gap: 60px;
  }
}
