.wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.videoBackground {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: -1;
}

.animationWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.animationWrapper > div {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  border: 1px solid #c4c4c4;
}

.indexMax {
  position: relative;
  z-index: 99;
}

.imagesWrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.image {
  width: 200px;
  position: absolute;
  z-index: 99;
  opacity: 0;
}

.slideImage {
  z-index: -1;
  position: relative;
  filter: grayscale(1);
}

.swiper {
  margin-top: 55px;
  margin-bottom: 10px;
}

.hovered .slideWrapper .imageText {
  opacity: 1;
}

.hovered .slideWrapper img {
  filter: grayscale(0);
}

.slideWrapper {
  position: relative;
  border: 1px solid #bdcbed;
  background: linear-gradient(180deg, rgba(14, 7, 38, 0) 40%, #0e0726 100%);
}

.imageText {
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  opacity: 0;
}

@media (max-width: 768px) {
  .image {
    width: 100px;
    position: absolute;
    z-index: 99;
    opacity: 0;
  }
}
