.royaltyCardContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.royaltyCard {
  width: 100%;
  border: 1px solid var(--lightMeta);
  background-color: var(--grayBlue);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-shadow: 0 0 105px rgba(202, 176, 255, 0.2);
}

.royaltyShareImg {
  width: 300px;
}

.royaltyShareContent {
  max-width: 630px;
}

.royaltyShareTitle {
  margin-bottom: 30px;
  max-width: 500px;
}

@media (min-width: 768px) {
  .royaltyCard {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .royaltyShareContent {
    min-width: 350px;
  }
}

@media (min-width: 992px) {
  .royaltyCard {
    padding: 0 0 0 35px;
  }

  .royaltyShareImg {
    width: 400px;
  }
}
