.wrapper {
  position: fixed;
  top: 10px;
  right: -100%;
  z-index: 9999;
  max-width: 400px;
  width: 100%;
  height: calc(100vh - 20px);
  background: rgba(14, 7, 38, 0.8);
  border: 2px solid #66698a;
  border-radius: 20px;
  backdrop-filter: blur(80px);
  padding: 50px 70px;
  transition: right 1s;
}

.lightWrapperBackground {
  background: rgba(255, 255, 255, 0.6);
  border: 2px solid #bdcbed;
  backdrop-filter: blur(180px);
}

.wrapper.opened {
  right: 10px;
  display: flex;
}

.menu{
  display: flex;
  flex-direction: column;
  gap:10px;
  max-height: 100%;
  position:relative;
  overflow: hidden;
}

.menuList::-webkit-scrollbar {
  width: 6px;
}

.menuList::-webkit-scrollbar-track {
  margin: 20vh 0;
  border-radius: 100px;
  background-color: transparent;
}

.menuList::-webkit-scrollbar-thumb {
  height: 30%!important;
  background-color: rgb(102, 105, 138, 0.5);
  border-radius: 100px;
}

.itemsContainer {
  padding-top: 20%;
}

.menuList{
  overflow-y: scroll;
}

.menuItem {
  cursor: pointer;
  width: fit-content;
  opacity: 0.3;
  transition: all 0.5s;
}

.menuItem::after {
  content: '';
  display: block;
  margin-top: 10px;
  height: 4px;
  width: 0;
  background: var(--gradient);
  transition: width 0.5s;
}

.menuItem:hover {
  opacity: 1 !important;
}

.activeMenuItem {
  opacity: 1;
}

.menuItem:hover::after,
.activeMenuItem::after {
  height: 4px;
  width: 100%;
}

.socialItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 65px;
  cursor: pointer;
}

.socialItemIcon {
  color: var(--white);
  font-size: 25px;
}

.socialItem:hover,
.socialItem:hover .socialItemIcon svg {
  color: var(--lightMeta);
  background: var(--gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-vertical {
  --color-stop-1: #00c3ff;
  --color-stop-2: #77e190;
  --color-stop-3: #ffff1c;
}

.closeIcon {
  font-size: 33px;
  cursor: pointer;
  transition: all 0.5s;
  color: var(--black);
}

.lightIcon {
  color: var(--white);
}

.closeIcon:hover {
  transform: rotate(360deg);
  color: var(--meta);
}

@media (max-width: 1400px) {
  .wrapper {
    width: 80%;
  }
}

@media (max-width: 1100px) {
  .wrapper {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .wrapper {
    width: calc(100% - 30px);
    padding-right: 25px;
  }

  .logoWrapper .closeIcon {
    font-size: 25px;
  }

  .logoWrapper .logo {
    max-width: 55px;
  }

  .menuItem {
    margin-bottom: 45px;
  }

  .socialItem {
    width: 25px;
  }
}