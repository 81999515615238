.chartInfo {
  background: rgba(14, 7, 38, 0.3);
  border: 1px solid var(--lightMeta);

  padding: 20px 0px;
}

.infoRow {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  cursor: pointer;
}

.active {
  background: rgba(169, 126, 255, 0.3);
}

.circle {
  cursor: pointer;
  transform-origin: center center;
}

.pieBox {
  height: 300px;
  width: 300px;

  position: relative;
  left: 50%;
  transform: translateX(-50%);

  margin-bottom: 30px;
}
.highlight {
  color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px;

  height: 175px;
  width: 175px;
  border-radius: 50%;

  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;

  z-index: 3;

  color: white;
  transition: background-color 0.5s linear;
}

.opacity {
  opacity: 0.7;
  transition: stroke 0.5s;
}

.backgroundGradient {
  position: absolute;
  width: 100%;
  height: 100%;

  left: 0;
  background: linear-gradient(180deg, #e757ff 0%, #a580ff 10.94%, #5aaeff 100%);
  filter: blur(550px);

  z-index: -1;
}

.mainSlices {
  position: relative;
}

.blackSlices {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;
}

@media (min-width: 768px) {
  .pieBox {
    height: 400px;
    width: 400px;
    margin-bottom: 0;
  }
  .highlight {
    height: 210px;
    width: 210px;
  }
}
