.waveContainer {
  position: relative;
  width: 100%;
  height: 300px;
  margin-top: 50px;
}

.wave {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
