.box {
  margin-top: 85px !important;
  justify-content: space-evenly;

  width: 100%;
}

.box .button {
  width: 100%;
  min-height: 75px;
  border: none;
  color: white;
  background-color: var(--babyBlue);
}

.box .button:hover {
  color: white;
  background-color: var(--babyBlue);
  border: none;
}

.infoBox {
  display: flex;
  flex-direction: column;
  background: rgba(14, 7, 38, 0.3);
  border: 2px solid rgba(189, 203, 237, 0.3);
  border-bottom: none;

  position: relative;
}

.side {
  flex: 1 0 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
}

.midCircle {
  min-width: 32px;
  min-height: 32px;
  padding: 4px;
  aspect-ratio: 1/1;
  background-color: black;
  border-radius: 50%;
  border: 2px solid rgba(189, 203, 237, 0.3);

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);

  text-shadow: 0px 3.18959px 3.18959px rgba(0, 0, 0, 0.25);
}

.verticalLine {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--gray1);
  height: 100%;
  width: 2px;
}

.horizontalLine {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--gray1);
  height: 2px;
  width: 100%;
}

@media (min-width: 768px) {
  .infoBox {
    flex-direction: row;
  }
}
