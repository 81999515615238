.wrapper {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 0!important;
}

.presalePage{
  padding-top: 90px;
}

.topSection {
  position: relative;
}

.joinTheRevWrapper {
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 100px 0;
}

.bgLeft{
  position: absolute;
  margin-left: -12px!important;
  left: 0;
  height: 100%;
  z-index: -1;
}

.bgRight{
  position: absolute;
  margin-right: -12px!important;
  right: 0;
  height: 100%;
  width: fit-content;
  z-index: -1;
}

.bgMobile{
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}

@media (max-width: 768px) {
  .wrapper {
    height: auto;
    background-image: none;
  }
}
