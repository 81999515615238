.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.diskTopLeft {
  position: absolute;
  left: 0;
  top: -80px;
}

.diskBottomLeft {
  position: absolute;
  left: -10px;
  top: 160px;
}

.backgroundWrapper {
  width: 100%;
}

.columnArtist {
  text-align: center;
}

.columnArtist img {
  filter: drop-shadow(0 0 52px #5aaeff);
}

@media (max-width: 768px) {
  .backgroundWrapper {
    background-image: none;
  }

  .backgroundWrapper > div > div > div:first-child {
    background-position-x: left;
    background-position-y: center;
  }

  .backgroundWrapper > div > div > div:nth-child(3) {
    background-position-x: center;
    background-position-y: center;
  }

  .backgroundWrapper > div > div > div:nth-child(5) {
    background-position-x: right;
    background-position-y: center;
  }

  .columnArtist img {
    max-width: none;
  }
}
