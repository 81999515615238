.box {
  padding: 50px 0;
  border: 2px solid var(--gray2);
  background: #201b3a99;
}

.titleBox {
  margin-bottom: 75px;
}

.title {
  font-size: 29px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.03em;
  text-align: left;
}

.description {
  margin: 30px auto 0 auto;
  max-width: 768px;
}

@media (max-width: 992px) {
  .box {
    background: none !important;
    border: none !important;
  }

  .description {
    margin-left: 0;
    width: 100%;
  }
}
