.wrapper {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  width: 100%;
  z-index: 4;
}

.alignLeft {
  display: flex;
  justify-content: start;
  padding-left: 20px;
}

.innerWrapper {
  padding: 8px;
  background: linear-gradient(
    90.46deg,
    rgba(14, 7, 38, 0.24) 0%,
    rgba(110, 57, 168, 0.24) 33.85%,
    rgba(14, 7, 38, 0.24) 69.27%,
    rgba(110, 57, 168, 0.24) 100%
  );
  backdrop-filter: blur(32px);
  border-radius: 70px;
  display: flex;
  align-items: center;
  position: relative;
  border: 2px solid rgb(170 124 255);
}

.rightIcon {
  color: var(--white);
}

.closeBtn {
  color: var(--white);
  background-color: var(--darkMeta);
  border-radius: 100%;
  padding: 3px;
  position: absolute;
  right: -35px;
  cursor: pointer;
}

.discordIcon {
  margin-right: 15px;
}

.lightMode .innerWrapper {
  background: linear-gradient(
    90.46deg,
    rgba(215, 221, 245, 0.24) 0%,
    rgba(255, 255, 255, 0.24) 33.85%,
    rgba(215, 221, 245, 0.24) 69.27%,
    rgba(255, 255, 255, 0.24) 100%
  );
}

.lightMode .rightIcon {
  color: rgba(231, 87, 255, 1);
}

.lightMode .closeBtn {
  color: var(--darkMeta);
  background: var(--grayBlueDoubleLight);
}

@media (min-width: 768px) {
  .innerWrapper {
    padding: 16px 24px;
  }

  .discordIcon {
    margin-right: 25px;
  }

  .description {
    max-width: 250px;
    text-align: left;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    margin-right: 40px;
  }

  .closeBtn {
    right: -2px;
    top: -2px;
  }
}
