.horizontalSlickSlider .slick-track {
  margin-left: 0 !important;
}

.horizontalSlickSlider .slick-slide > div {
  margin: 0 10px;
}
.horizontalSlickSlider .slick-list {
  margin: 0 -10px;
}

.horizontalSlickSlider .slick-prev::before,
.horizontalSlickSlider .slick-next::before {
  content: '' !important;
}

@media (min-width: 1024px) {
  .horizontalSlickSlider .slick-slide > div {
    margin: 0 7px;
  }
  .horizontalSlickSlider .slick-list {
    margin: 0 -7px;
  }
}
@media (max-width: 768px) {
  .horizontalSlickSlider .slick-slider {
    width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 500px) {
  .horizontalSlickSlider .slick-slider {
    width: 100%;
  }
  .horizontalSlickSlider .slick-list {
    margin-left: -35px !important;
  }
  .horizontalSlickSlider .slick-trck {
    padding: 0 !important;
  }
}
