.footerContainer {
  background: linear-gradient(88.63deg, #97b0ff 0%, #c36aff 100%);
  padding: 50px 10% !important;
  border-radius: 40px 40px 0px 0px;
}

.footerHeader {
  border-bottom: 1px solid #ffffff20;
  margin-bottom: 30px;
}

.footerTitle {
  font-size: 28px;
  margin-bottom: 10px;
  font-weight: 600;
}

.footerDescription {
  max-width: 500px;
  opacity: 0.55;
}

.footerIcons {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.socialIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  width: max-content;
  color: white;
  cursor: pointer;
}

.whiteButton{
  display: flex;
  align-items: center;
  padding: 8px 9px;
  margin: 30px 0;
  gap: 8px;
  border-radius: 12px;
  background-color: var(--white);
  width: fit-content;
  cursor: pointer;
  font-weight: 600;
  color: var(--meta);
  font-size: 17px;
  line-height: 17px;
  letter-spacing: -0.03em;
  text-align: left;
  opacity: 0.8;
}

.downloadIcon{
  color: var(--meta);
}

.footerAdress {
  opacity: 0.7;
}

.footerMenu {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 60px;
  row-gap: 35px;
  margin-top: 25px;
}

.menuTitle {
  margin-bottom: 10px;
  cursor: pointer;
}

.menuSubpages div {
  margin-bottom: 10px;
  cursor: pointer;
}

.menuRow:last-child .menuSubpages {
  display: flex;
  gap: 35px;
}

.footerFootnote {
  display: flex;
  flex-direction: column;
  gap: 15px;
  opacity: 0.7;
}

.bar {
  display: none;
}

@media (min-width: 768px) {
  .footerFootnote {
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }

  .bar {
    display: initial;
  }
  .menuRow:last-child .menuSubpages {
    display: initial;
    gap: 0;
  }
  .menuTitle {
    margin-bottom: 20px;
  }
  .menuSubpages div {
    margin-bottom: 15px;
  }
}

@media (min-width: 992) {
  .footerContainer {
    padding: 50px 145px !important;
  }
}
