.wrapper {
  display: flex;
  gap: 50px;
  justify-content: center;
  margin: 40px 0;
}

.rowBlock h1 {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .wrapper {
    gap: 0;
  }

  .rowBlock h1 {
    text-align: center;
    display: block;
  }
}
