.chartInfo {
  background: rgba(14, 7, 38, 0.3);
  border: 1px solid var(--lightMeta);
  padding: 20px 0;
}

.container {
  margin-top: 150px
}


.infoRow {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  cursor: pointer;
}

.firstBackgroundGradient {
  position: absolute;
  width: 50%;
  height : 50%;
  left:25%;
  top:25%;
  background: linear-gradient(224deg, #E757FF 0%, #A580FF 10.94%, #5AAEFF 100%);
  filter: blur(200px);
  opacity: 0.8;
  border-radius: 50%;
  z-index: -1;
  transform: rotate(160deg);
}

.secondBackgroundGradient{
  position: absolute;
  width: 50%!important;
  height : 50%;
  left:10%;
  top:25%;
  background: linear-gradient(180deg, rgba(0, 194, 255, 0) 0%, #FF29C3 100%);
  filter: blur(200px);
  border-radius: 50%;
  z-index: -1;
  transform: rotate(170deg);
  opacity: 0.3;
}

@media (max-width: 768px){
  .firstBackgroundGradient {
    top:50%;
    filter: blur(250px);
  }
}