.presalesCardsContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  padding: 0;
}

.presalesCard p {
  margin: 0;
}

.presalesCard {
  position: relative;
  width: 200px;
  min-height: 100px;
  padding: 10px;
  background-color: transparent;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.presalesCard img {
  width: 115px;
}

.text1 {
  font-size: 11px;
  line-height: 1.5;
}

.text2 {
  font-size: 18px;
}

.externalLinkIcon{
  width: 24px!important;
  height: fit-content;
}

@media (min-width: 400px) {
  .presalesCard:nth-child(odd):last-child {
    max-width: 50%;
  }
}

@media (min-width: 815px) {
  .presalesCard:nth-child(odd):last-child {
    max-width: initial;
  }
}

@media (min-width: 1300px) {
  .presalesCard {
    min-height: 150px;
  }

  .text1 {
    font-size: 16px;
  }

  .text2 {
    font-size: 25px;
  }

  .presalesCard img {
    width: 165px;
  }
}
