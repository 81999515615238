.customButton{
    min-width: 0!important;
    padding: 0 20px!important;
}

.customButton:after {
    content: none!important;
}

.customButton:before {
    content: url("../../assets/icons/arrowIconWhite.svg")!important;
}

.customButton:hover::before {
    transition: all ease-in 0.5s;
    animation: bounce 3s infinite;
    filter: brightness(3)!important;
}

.sentButton {
    min-width: 0!important;
}

.sentButton:after {
    content: none!important;
}

@keyframes bounce {
    15% {
        transform: translateX(-2px);
    }
    30% {
        transform: translateX(5px);
    }
    100% {
        transform: translateX(0px);
    }
}