.card {
  height: 100%;
  border: 1px solid var(--lightMeta);
  text-align: center;
  display: grid;
  grid-auto-rows: 1fr;
  padding: 35px 20px;
  margin-bottom: 45px;
}

.image {
  height: 40px;
  margin-bottom: 20px;
}

.bottom {
  padding: 0 1.5rem;
  padding-top: 20px;
}

.card:hover {
  opacity: 1;
  transform: scale(1.01);
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  background: radial-gradient(
    168.62% 192.18% at 7.76% 137.65%,
    rgba(231, 87, 255, 0.3) 0%,
    rgba(231, 87, 255, 0) 100%
  );
  box-shadow: 0 0 120px rgba(231, 87, 255, 0.32);
  position: relative;
  z-index: 999;
  animation: 5s rotate linear infinite;
}

@media (min-width: 1200px) {
  .bottom {
    padding-top: 50px;
  }
}
