.wrapper {
  padding: 60px 0;
  width: 100%;
  text-align: center;
  min-height: 70vh;
}

.imagesBackground {
  width: 50%;
  height: 50%;
  min-height: 300px;
  margin-right: auto;
  margin-left: auto;
  background: linear-gradient(180deg, #e757ff 0%, #a580ff 10.94%, #5aaeff 100%);
  filter: blur(150px);
}

.imagesWrapper {
  position: absolute;
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}

.image1 {
  max-width: 180px;
  position: relative;
  top: 30px;
  left: 30px;
  z-index: 1;
}

.image2 {
  max-width: 135px;
  position: relative;
  top: -30px;
  left: -30px;
}

.buttonsContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

@media (min-width: 768px) {
  .wrapper {
    padding-top: 200px;
    padding-bottom: 100px;
  }

  .imagesBackground {
    position: relative;
    width: 75%;
    height: 75%;
    margin-right: initial;
    margin-left: auto;
    filter: blur(300px);
    transform: rotate(-105deg);
  }

  .imagesWrapper {
    position: absolute;
    right: 65px;
    top: 0;
    display: block;
  }

  .image1 {
    max-width: initial;
    position: relative;
    right: -65px;
    top: 100px;
  }

  .image2 {
    max-width: initial;
    top: 0;
    right: 0;
  }
}
