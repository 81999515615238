.wrapper {
  margin-bottom: 20px;
}

.phasesWrapper {
  display: flex;
  overflow-x: scroll;
  padding-top: 25px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  cursor: grab;
  user-select: none;
}

.phasesWrapper::-webkit-scrollbar {
  display: none;
}

.phaseWrapper {
  max-width: 400px;
  width: 400px;
  flex: 0 0 auto;
}

.line {
  max-width: 400px;
  width: 100%;
  height: 1px;
  background-color: #bdcbed;
  position: relative;
  overflow: visible;
  margin-bottom: 50px;
}

.line::after {
  content: url("../../../../assets/images/Polygon 12.svg");
  display: block;
  position: relative;
  top: -14px;
}

.phaseWrapper:first-child .line::after,
.phaseWrapper:last-child .line::before {
  content: "";
  display: block;
  height: 20px;
  background-color: #bdcbed;
  width: 1px;
  position: relative;
  top: -10px;
}

.phaseWrapper:last-child .line::before {
  position: absolute;
  right: 0;
}

.activePhase {
  opacity: 0.3;
}

.paginationWrapper {
  height: 8px;
  width: 100%;
  margin-top: 100px;
  background-color: var(--gray1Color);
  position: relative;
}

.pagination {
  position: absolute;
  height: 8px;
  max-width: 450px;
  width: 100%;
  background: var(--primaryGradient);
}

@media (max-width: 768px) {
  .phaseWrapper {
    max-width: 300px;
  }

  .paginationWrapper {
    margin-top: 25px;
  }

  .pagination {
    max-width: 100%;
  }
}
