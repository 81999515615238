.wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.iframe {
  position: absolute;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}

.heroImage {
  position: absolute;
  width: 100%;
}

.heroWrapper {
  background-size: cover;
  background-position: center;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
  padding-top: 75px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

@media (max-width: 1000px) {
  .wrapper {
    height: auto;
  }

  .heroWrapper > div > video {
    min-width: 100%;
    min-height: 100%;
    width: auto !important;
    height: 100vh !important;
  }
}

@media (max-height: 700px) {
  .wrapper {
    height: auto;
  }
}

@media (max-width: 768px) {
  .wrapper {
    height: auto;
  }

  .heroWrapper {
    height: auto;
    justify-content: center;
  }
}

.bounce {
  animation: bounce 4s infinite;
}

@keyframes bounce {
  50% {
    transform: translateY(-5px);
  }
}
