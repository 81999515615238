.wrapper {
  background: var(--blueGrayDark);
  border: 1px solid rgba(189, 203, 237, 0.3);
  box-sizing: border-box;
  box-shadow: 0 0 39px rgba(90, 174, 255, 0.37);
  border-radius: 41px;
  padding: 5px 6px;
}

.selectWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.select:global.form-select {
  border: none;
  background: transparent;
  color: var(--white);
  padding: 0 0 0 30px;
  position: relative;
  z-index: 1;
}

.select:global.form-select option {
  color: var(--black);
}

.select.lightMode:global.form-select {
  border: none;
  background: transparent;
  color: var(--black);
}

.globeIcon {
  position: absolute;
  left: 0;
}

.select:global.form-select:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.option {
  border-radius: 50%;
  width: 33px;
  height: 33px;
  font-size: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--whiteColor);
  padding: 7px;
  cursor: pointer;
}

.active {
  background-color: var(--lightMeta);
  color: var(--black);
}

.wrapper.sticky {
  background: var(--whiteColor);
  box-shadow: none;
}

.sticky .option {
  color: var(--secondaryColor);
}

.sticky .active {
  color: var(--whiteColor);
  background-color: var(--secondaryColor);
}
