.gradientBackground {
  background: var(--primaryGradient);
  padding: 0 24px;
}

.countColumn {
  padding: 10px 50px !important;
}

.rightBorder {
  border-right: 1px solid var(--whiteColor);
}

@media (max-width: 768px) {
  .countColumn {
    padding: 10px calc(var(--bs-gutter-x) * 0.5) !important;
  }

  .rightBorder {
    border-right: none;
    border-bottom: 1px solid var(--whiteColor);
  }
}
