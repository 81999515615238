.wrapper {
  text-align: center;
  position: relative;
}

.contentSpacing {
  margin-bottom: 85px;
}

.firstBackgroundGradient {
  position: absolute;
  height: 50%;
  width: 50% !important;
  top: 10%;
  background: linear-gradient(180deg, #ff29c3 0%, rgba(0, 194, 255, 0) 100%);
  filter: blur(150px);
  border-radius: 50%;
  z-index: -1;
  transform: rotate(170deg);
  opacity: 0.3;
}

.secondBackgroundGradient {
  position: absolute;
  width: 50% !important;
  height: 50%;
  left: 30%;
  background: linear-gradient(180deg, #e757ff 0%, #a580ff 10.94%, #5aaeff 100%);
  filter: blur(250px);
  border-radius: 50%;
  z-index: -1;
  transform: rotate(0deg);
  opacity: 0.6;
}
