.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.mockShadow {
  filter: drop-shadow(0 0 165px rgba(90, 174, 255, 0.6));
  height: 100%;
}

.buttonsWrapper {
  gap: 20px;
  margin-left: -10px;
}

.iphoneWrapper {
  position: relative;
  display: flex;
  justify-content: center;
}

.turnedOffIphone {
  filter: drop-shadow(0 34px 34px #0e0726) drop-shadow(0 0 165px rgba(90, 174, 255, 0.6));
}

.turnedOnIphone {
  position: absolute;
  opacity: 0;
  transition: all 1s;
  transition-delay: 1s;
}

@media (max-width: 768px) {
  .buttonsWrapper {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .buttonsWrapper button {
    margin: 0;
  }

  .mockShadow {
    max-width: 250px;
    height: auto;
  }

  .turnedOffIphone,
  .turnedOnIphone {
    max-width: 60%;
  }
}
