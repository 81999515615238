.calculatorBox {
  padding: 45px 40px 30px;
  margin-top: 70px;
  background: #201b3a;
  border: 1.5px solid #66698a;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.07);
  backdrop-filter: blur(120px);
}

.lightMetaColor {
  color: var(--lightMeta);
}

.slider {
  position: relative;
  -webkit-appearance: none;
  width: 100%;
  height: 4px !important;
  outline: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 2px 2px 0 0;
  margin-top: 20px;
}

.slider::-webkit-slider-thumb:hover {
  width: 30px;
  height: 30px;
  box-shadow: 0 3px 25px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: var(--fucsia);
  cursor: pointer;
  border-radius: 100%;
}

.stakingInfo {
  padding: 25px 40px;
  background: rgba(46, 32, 82, 0.6);
  border: 1.5px solid rgba(102, 105, 138, 0.55);
  backdrop-filter: blur(20px);
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.clpInfo {
  color: var(--lightMeta);
}

.clpAmount {
  border-bottom: 1px solid var(--gray2);
  padding-bottom: 8px;
}

.clpDescription {
  margin-top: 10px;
  color: var(--gray2);
}

.ruler {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: -10px;
}

.step {
  color: #6c7d8b;
  font-weight: var(--bold);
  font-size: 18px;
  display: flex;
  flex-direction: column;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.verticalLine {
  width: 3px;
  border-radius: 0 0 3px 3px;
  border: 2px solid #d2dce9;
  background-color: #d2dce9;
  height: 25px;
  margin-bottom: 10px;
}

.applyButton {
  margin-top: 50px;
  color: var(--whiteColor);
}

.flexCenter {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-top: 16px;
}

.bonuses {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
}

.tooltipRow {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 16px;
}

.tooltipRow img {
  cursor: pointer;
}

.tooltip {
  margin-top: 25px;
  background: #3a3858;
  box-shadow: 0 2px 4px rgba(37, 37, 37, 0.05);
  border-radius: 10px;
  padding: 12px 24px;
  position: absolute;
  top: 20%;
  left: 50%;
  width: 25%;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent #3a3858;
}
