.widget {
  height: 630px;
  width: 100%;
  border: 1px solid rgba(202, 176, 255, 0.5);
  padding: 0 !important;
  overflow: hidden;
}

.veriffWidget {
  background: url("https://images.typeform.com/images/rgjAhgantmMp/background/large");
  width: 100%;
  display: flex;
  height: 630px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pretitle {
  text-align: center;
}

.description {
  margin-top: 15px;
  width: 70%;
  font-size: 14px;
}

.startNewSession {
  width: 300px;
  background-color: #004e5f;
  color: white;
  box-sizing: border-box;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0.4px;
  display: inline-block;
  text-align: center;
  user-select: none;
  border: 1px solid transparent;
  position: relative;
  height: 40px;
  padding: 0 16px;
  border-radius: 4px;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  transition-property: background-color, color, border-color, box-shadow, transform;
  cursor: pointer;
}

.title {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
  text-align: center;
}

.selectMode {
  background: var(--black);
  border: 3px solid var(--darkMeta);
  border-radius: 25px;
  padding: 10px 25px;
  cursor: pointer;
}

.selectMode:global.active {
  background: var(--blueGrayDark);
  box-shadow: 0 0 30px rgba(231, 87, 255, 0.5);
  border-radius: 25px;
}

@media (min-width: 768px) {
  .widget {
    height: 630px;
  }

  .title {
    margin-bottom: 70px !important;
  }

  .selectMode {
    padding: 25px 25px;
  }
}
